@keyframes bounce {
	from {transform:scale(1.0,1.0);}
	15% {transform:scale(1.2,1.2);}
	30% {transform:scale(1.0,1.0);}
	to {transform:scale(1.0,1.0);}
}

@keyframes backAndForth {
  0% { transform: translateX(0); }
  10% { transform: translateX(0); }
  45% { transform: translateX(calc(-100% + 200px)); }
  55% { transform: translateX(calc(-100% + 200px)); }
  90% { transform: translateX(0); }
  100% { transform: translateX(0); }
}