body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
	max-height: 100%;
	max-width: 100%;
}

a {
	color: lightBlue;
}

.MainContainer {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.FollowScreenContainer {
	height: 100%;
	width: 100%;
}

.ContentContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex:1;
	flex-direction: column;
	align-items: stretch;
}

.Content {
	flex: 1;
	background-color: orange;
}

.ChoiceBackground {
	background: linear-gradient(-45deg, #00B1FF, #4E00FF);
	background-size: 400% 400%;
	height: 20%;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid white;
	animation: gradient 10s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}